<template>
  <Toast />
  <ul class="list-none p-0 m-0 flex align-items-center font-medium mb-3">
    <li>
      <a class="text-500 no-underline line-height-3 cursor-pointer">Reportes</a>
    </li>
    <li class="px-2">
      <i class="pi pi-angle-right text-500 line-height-3"></i>
    </li>
    <li>
      <span class="text-900 line-height-3 active"
        >Fecha Vence de Productos</span
      >
    </li>
  </ul>
  <div class="surface-section px-4 py-5 md:px-12 lg:px-12">
    <div
      class="flex align-items-center flex-column lg:justify-content-center lg:flex-row"
    >
      <h4><strong>REPORTE FECHA VENCE DE PRODUCTOS</strong></h4>
    </div>
    <div class="col-12">
      <div class="card p-fondo">
        <div class="p-fluid formgrid grid">
          <div class="field col-12 md:col-2">
            <label for="sucursales"><strong>SUCURSAL: </strong></label>
            <Dropdown
              v-model="sucursalSeleccionada"
              :options="sucursales"
              optionLabel="nombre"
              optionValue="id"
            />
          </div>
          <div class="field col-12 md:col-4">
            <label for=""><strong>NOMBRE PRODUCTO: </strong></label>
            <AutoComplete
              :dropdown="true"
              field="descripcion"
              v-model="productoSelected"
              :suggestions="productosListadoFiltrado"
              @complete="buscarProducto($event)"
              placeholder="Escriba Nombre Producto"
            >
              <template #item="slotProps">
                <div>
                  <strong>{{ slotProps.item.descripcion }} **</strong>
                 <!--  {{ slotProps.item.fabricas.nombre_proveedor
                  }} --><!-- <span><strong> **</strong></span> -->
                  {{ slotProps.item.fabrica_nombre }}
                </div>
              </template>
            </AutoComplete>
          </div>
          <div class="field col-12 md:col-2">
            <label for="fecha_inicio"
              ><strong>FECHA VENCE INICIO: </strong></label
            >
            <Calendar
              id="fecha_inicio"
              v-model="fecha_inicio"
              :showIcon="true"
              dateFormat="dd/mm/yy"
            />
          </div>
          <div class="field col-12 md:col-2">
            <label for="fecha_fin"><strong>FECHA VENCE FIN: </strong></label>
            <Calendar
              id="fecha_fin"
              v-model="fecha_fin"
              :showIcon="true"
              dateFormat="dd/mm/yy"
            />
          </div>

          <div class="field col-12 md:col-2">
            <label for="fecha_vencimiento_hasta"
              ><strong>ACCIONES: </strong></label
            >
            <Button
              label="APLICAR FILTROS"
              icon="pi pi-search"
              class="p-button-primary mr-2"
              :loading="enviando"
              :disabled="enviando"
              @click="BuscarFiltro"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="mt-4">
      <DataTable
        ref="dtKardex"
        key="id"
        :value="fecha_vence"
        responsiveLayout="scroll"
        :loading="enviando"
        class="p-datatable-sm"
        :filters="buscar"
        responsive="true"
        stripedRows
        showGridlines
        selectionMode="single click"
        :rows="50"
        :paginator="true"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        :rowsPerPageOptions="[50, 100, 500, 1000, 5000, 10000]"
        currentPageReportTemplate="Mostrando {first} de {last} de {totalRecords} Reportes de Fecha Vence de Productos"
        @row-dblclick="updateFechaVence($event.data)"
      >
        <template #header>
          <div
            class="flex flex-column md:flex-row md:justify-content-end md:align-items-end"
          >
            <span class="block mt-2 md:mt-0 p-input-icon-left mr-2">
              <i class="pi pi-search" />
              <InputText
                v-model="buscar['global'].value"
                placeholder="Buscar en Fecha Vence..."
              />
            </span>
            <span class="block mt-2 md:mt-0 p-input-icon-left mr-2">
              <Button
                label="PDF Fecha Vence"
                type="button"
                icon="pi pi-file-pdf"
                class="p-button-outlined p-button-danger"
                v-tooltip.top="'Exportar PDF Fecha Vence'"
                :loading="generando_pdf"
                :disabled="generando_pdf"
                @click="generarPDF()"
              />
            </span>
            <span class="block mt-2 md:mt-0 p-input-icon-left">
              <Button
                label="Excel Fecha Vence"
                type="button"
                icon="pi pi-file-excel"
                class="p-button-outlined p-button-success"
                v-tooltip.top="'Exportar Excel Fecha Vence'"
                :loading="exportando"
                :disabled="exportando"
                @click="exportFechaVence()"
              />
            </span>
          </div>
        </template>
        <template #loading
          ><div class="flex align-items-center justify-content-center">
            <ProgressSpinner /></div
        ></template>
        <template #empty>
          <span class="p-invalid">
            Debe aplicar filtros para ver resultados en la tabla!</span
          >
        </template>
        <Column header="#">
          <template #body="slotProps">
            {{ 1 + slotProps.index }}
          </template>
        </Column>
        <Column
          :class="'text-right'"
          field="oc_recepcion_id"
          header="OC_ID"
        ></Column>
        <Column field="nombre_sucursal" header="SUCURSAL" v-if="sucursales.length > 2"></Column>
        <Column field="nombre_laborario" header="LABORATORIO"></Column>
        <Column field="nombre_producto" header="PRODUCTO" style="max-width: 300px"></Column>;
        <Column
          :class="'text-right'"
          field="cantidad"
          header="CANTIDAD"
        ></Column>
        <Column field="fecha_vence" header="FECHA VENCIMIENTO"></Column>
        <Column :class="'text-right'" field="lote" header="LOTE"></Column>
        <Column header="ACCIONES">
          <template #body="slotProps">
            <Button
              class="p-button-icon-only p-button-raised"
              v-tooltip.top="'Ver Acciones'"
              icon="pi pi-ellipsis-v"
              @click="$refs['menu' + slotProps.data.id].toggle($event)"
            />
            <Menu
              :ref="'menu' + slotProps.data.id"
              :popup="true"
              :model="acciones(slotProps.data)"
            >
            </Menu>
          </template>
        </Column>
      </DataTable>
      <ActualizarFechaVence
        :show="actualizarFechaVenceDialog"
        :actualizarFechaVence="{ ...actualizarFechaVence }"
        @closeModal="cerrarModalActualizarFechaVence"
        @actualizarlistado="actualizarlistado"
      />
    </div>
  </div>
</template>
<script>
import { FilterMatchMode } from "primevue/api";
import ProductService from "@/service/ProductService";
import SucursalService from "@/service/SucursalService";
import ActualizarFechaVence from "@/module/reportes/ActualizarFechaVence.vue";
import { useAuth } from "@/stores";

export default {
  components: {
    ActualizarFechaVence,
  },
  data() {
    return {
      sucursales: [{ id: 0, nombre: "TODAS" }],
      sucursalSeleccionada: 0,
      fecha_vence: [],
      productoSelected: null,
      productosListadoFiltrado: [],
      enviando: false,
      generando_pdf: false,
      exportando: false,
      fecha_inicio: null,
      fecha_fin: null,
      buscar: {
        global: { matchMode: FilterMatchMode.CONTAINS, value: null },
      },
      actualizarFechaVenceDialog: false,
      actualizarFechaVence: {},
    };
  },
  productService: null,
  sucursalService: null,
  auth: null,
  created() {
    this.productService = new ProductService();
    this.sucursalService = new SucursalService();
    this.auth = useAuth();
  },
  mounted() {
    this.cargarSucursales();
  },
  methods: {
    exportFechaVence() {
      if (this.fecha_vence.length > 0) {
        this.exportando = true;
        let datos = {
          fecha_inicio: this.fecha_inicio,
          fecha_fin: this.fecha_fin,
          sucursal_id: this.sucursalSeleccionada,
          producto_id: this.productoSelected ? this.productoSelected.id : null,
        };
        this.productService.exportFechaVence(datos).then(() => {
          this.exportando = false;
          this.$toast.add({
            severity: "success",
            summary: "Exito",
            detail: "Excel generado correctamente",
            life: 3000,
          });
        });
      } else {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Debe aplicar filtros para exportar",
          life: 3000,
        });
      }
    },
    generarPDF() {
      if (this.fecha_vence.length > 0) {
        this.generando_pdf = true;
        let datos = {
          fecha_inicio: this.fecha_inicio,
          fecha_fin: this.fecha_fin,
          sucursal_id: this.sucursalSeleccionada,
          producto_id: this.productoSelected ? this.productoSelected.id : null,
        };
        this.productService.printRFechaVence(datos).then(() => {
          this.generando_pdf = false;
          this.$toast.add({
            severity: "success",
            summary: "Exito",
            detail: "PDF generado correctamente",
            life: 3000,
          });
        });
      } else {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Debe aplicar filtros para generar el PDF",
          life: 3000,
        });
      }
    },
    actualizarlistado() {
      this.BuscarFiltro();
    },
    acciones(datos) {
      let permisosUsuarioAuth = this.auth.user.permissions;
      return [
        {
          label: "ACTUALIZAR FECHA VENCIMIENTO",
          icon: "pi pi-pencil",
          disabled:
            "R_FechaVence Actualizar" in permisosUsuarioAuth ? false : true,
          command: () => this.updateFechaVence(datos),
        },
      ];
    },
    updateFechaVence(datos) {
      this.actualizarFechaVence = { ...datos };
      this.actualizarFechaVenceDialog = true;
    },

    cerrarModalActualizarFechaVence() {
      this.actualizarFechaVenceDialog = false;
    },
    buscarProducto(event) {
      setTimeout(() => {
        this.productService
          .buscarXNombre({ texto: event.query, sucursal_id: this.sucursalSeleccionada })
          .then((response) => {
            this.productosListadoFiltrado = [...response.productos];
          })
          .catch((error) => console.log(error));
      }, 250);
    },
    BuscarFiltro() {
      this.fecha_vence = [];
      this.enviando = true;
      this.cargarProductosFiltros();
    },
    cargarProductosFiltros() {
      let datos = {
        sucursal_id:
          this.sucursalSeleccionada == 0 ? null : this.sucursalSeleccionada,
        producto_id: this.productoSelected ? this.productoSelected.id : null,
        fecha_inicio: this.fecha_inicio,
        fecha_fin: this.fecha_fin,
      };
      this.productService
        .filtrarFechaVence(datos)
        .then((response) => {
          this.fecha_vence = response.fecha_vence;
          this.enviando = false;
        })
        .catch(() => {
          this.enviando = false;
        });
    },
    cargarSucursales() {
      this.sucursalService
        .getSucursalesAll()
        .then((response) => {
          response.forEach((sucursal) => {
            this.sucursales.push(sucursal);
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/demo/badges.scss";
.p-invalid {
  color: red;
}
.p-fondo {
  border-radius: 1%;
}
.p-fondo {
  background-color: #7bffae;
}
</style>
